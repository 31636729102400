
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as forgot_45passwordQVyJ1Re5MVMeta } from "/builds/simpli_flow/webapp/web/app/pages/auth/forgot-password.vue?macro=true";
import { default as loginXDtIouhGTxMeta } from "/builds/simpli_flow/webapp/web/app/pages/auth/login.vue?macro=true";
import { default as _91clientId_93FuwqFoh3yIMeta } from "/builds/simpli_flow/webapp/web/app/pages/clients/[clientId].vue?macro=true";
import { default as index27RGQPpPmxMeta } from "/builds/simpli_flow/webapp/web/app/pages/clients/index.vue?macro=true";
import { default as privacyj0bepeOjwzMeta } from "/builds/simpli_flow/webapp/web/app/pages/legal/privacy.vue?macro=true";
import { default as slaXGtnYAh3FHMeta } from "/builds/simpli_flow/webapp/web/app/pages/legal/sla.vue?macro=true";
import { default as tosqTMb25e8QTMeta } from "/builds/simpli_flow/webapp/web/app/pages/legal/tos.vue?macro=true";
import { default as organizations77HJzdbC6JMeta } from "/builds/simpli_flow/webapp/web/app/pages/organizations.vue?macro=true";
import { default as indexuQlF2huLjpMeta } from "/builds/simpli_flow/webapp/web/app/pages/settings/index.vue?macro=true";
import { default as membersYrda8KkVuMMeta } from "/builds/simpli_flow/webapp/web/app/pages/settings/members.vue?macro=true";
import { default as notificationsuxEO4RnWL1Meta } from "/builds/simpli_flow/webapp/web/app/pages/settings/notifications.vue?macro=true";
import { default as settingsKz6XQPkikdMeta } from "/builds/simpli_flow/webapp/web/app/pages/settings.vue?macro=true";
import { default as _91id_93dfZXnPmV9hMeta } from "/builds/simpli_flow/webapp/web/app/pages/test/[id].vue?macro=true";
import { default as usersiv0ogudBpeMeta } from "/builds/simpli_flow/webapp/web/app/pages/users.vue?macro=true";
export default [
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordQVyJ1Re5MVMeta || {},
    component: () => import("/builds/simpli_flow/webapp/web/app/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginXDtIouhGTxMeta || {},
    component: () => import("/builds/simpli_flow/webapp/web/app/pages/auth/login.vue")
  },
  {
    name: "clients-clientId",
    path: "/clients/:clientId()",
    component: () => import("/builds/simpli_flow/webapp/web/app/pages/clients/[clientId].vue")
  },
  {
    name: "clients",
    path: "/clients",
    component: () => import("/builds/simpli_flow/webapp/web/app/pages/clients/index.vue")
  },
  {
    name: "legal-privacy",
    path: "/legal/privacy",
    meta: privacyj0bepeOjwzMeta || {},
    component: () => import("/builds/simpli_flow/webapp/web/app/pages/legal/privacy.vue")
  },
  {
    name: "legal-sla",
    path: "/legal/sla",
    meta: slaXGtnYAh3FHMeta || {},
    component: () => import("/builds/simpli_flow/webapp/web/app/pages/legal/sla.vue")
  },
  {
    name: "legal-tos",
    path: "/legal/tos",
    meta: tosqTMb25e8QTMeta || {},
    component: () => import("/builds/simpli_flow/webapp/web/app/pages/legal/tos.vue")
  },
  {
    name: "organizations",
    path: "/organizations",
    meta: organizations77HJzdbC6JMeta || {},
    component: () => import("/builds/simpli_flow/webapp/web/app/pages/organizations.vue")
  },
  {
    name: settingsKz6XQPkikdMeta?.name,
    path: "/settings",
    component: () => import("/builds/simpli_flow/webapp/web/app/pages/settings.vue"),
    children: [
  {
    name: "settings",
    path: "",
    component: () => import("/builds/simpli_flow/webapp/web/app/pages/settings/index.vue")
  },
  {
    name: "settings-members",
    path: "members",
    component: () => import("/builds/simpli_flow/webapp/web/app/pages/settings/members.vue")
  },
  {
    name: "settings-notifications",
    path: "notifications",
    component: () => import("/builds/simpli_flow/webapp/web/app/pages/settings/notifications.vue")
  }
]
  },
  {
    name: "test-id",
    path: "/test/:id()",
    component: () => import("/builds/simpli_flow/webapp/web/app/pages/test/[id].vue")
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/builds/simpli_flow/webapp/web/app/pages/users.vue")
  }
]