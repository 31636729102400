export default defineNuxtRouteMiddleware((to) => {
    const { loggedIn } = useAuthSession()
    
    // If user is not authenticated and trying to access protected routes
    if (!loggedIn.value && !(to.path.includes('/auth/') || to.path.includes('/legal/'))) {
      return navigateTo('/auth/login')
    }
    
    // If user is authenticated and trying to access auth pages
    if (loggedIn.value && (to.path.includes('/auth/') || to.path === '/')) {
      return navigateTo('/clients');
    }
  })